.App {
  text-align: center;
}

.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.TitleText {
  font-family: "Titillium Web", sans-serif;
  font-size: calc(20px + 2vmin);
  line-height: 1.125;
}

.container {
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
}